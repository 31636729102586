import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Seo from "../components/seo"
const IndexPage = () => {
  return (
      
      
      <Layout>
      <Seo title="1/1 NFT Artist & Musician" description="Music Producer and 2D Motion Artist, making art to feel human."/> 
      <Hero/>
      </Layout>
    
  )
}   

export default IndexPage